import * as React from 'react';
import { useState, useEffect } from 'react';

type Header = {
  stockNo: string;
  maker: string;
  model: string;
  chassisNo: string;
  mast: string;
  mastHeight: string;
  bidPrice: string;
  company: string;
  scNo: string;
};

type Vehicle = {
  id: number;
  image?: string;
  stockNo: string;
  maker: string;
  model: string;
  chassisNo: string;
  mast: string;
  mastHeight: number;
  bidPrice: number;
  company: string;
  scNo?: string;
  linkName: string;
  linkPath: string;
};

type Props = {
  title: string;
  headers: Header[];
  vehicles: Vehicle[];
  vehicleIds: string;
  defaultVehicleIds: number[];
  isSc: boolean;
  isIvEdit: boolean;
};

export const VehicleCheckTable = ({
  title,
  headers,
  vehicles,
  vehicleIds,
  defaultVehicleIds,
  isSc,
  isIvEdit,
}: Props) => {
  const [displayCheckbox, setDisplayCheckbox] = useState(!isIvEdit);
  const [ids, setIds] = useState(defaultVehicleIds);
  const [countIds, setCountIds] = useState(0);
  const [sumPrices, setSumPrices] = useState(0);
  const sum = (array: number[]) => array.reduce((total, value) => (total + value), 0);
  const currency = (price: number) => `${new Intl.NumberFormat().format(price)} JPY`;

  useEffect(() => {
    setCountIds(ids.length);
    setSumPrices(sum(vehicles.filter(v => ids.includes(v.id)).map(v => v.bidPrice)));
  }, [ids]);

  const handleChange = (vehicleId: number) => {
    setIds(ids.includes(vehicleId) ? ids.filter((id) => id !== vehicleId) : [...ids, vehicleId]);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setDisplayCheckbox(!displayCheckbox);
    setIds(defaultVehicleIds);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>{title}</div>
        <div className="text-danger">合計: {countIds}台 / {currency(sumPrices)}</div>
      </div>
      <table className="table thead-middle">
        <thead className="bg-dark text-light">
          {headers.map((header) => (
            <tr key={header.stockNo}>
              <th></th>
              <th></th>
              <th></th>
              <th>{header.stockNo}</th>
              <th>{header.maker}</th>
              <th>{header.model}</th>
              <th>{header.chassisNo}</th>
              <th>{header.mast}</th>
              <th>{header.mastHeight}</th>
              <th>{header.bidPrice}</th>
              <th>{header.company}</th>
              {!isSc && <th>{header.scNo}</th>}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody>
          {
            vehicles.map((vehicle, index) => (
              <tr key={vehicle.id}>
                <td>{index + 1}</td>
                <td>
                  <div className="form-group form-check">
                    <input
                      disabled={!displayCheckbox}
                      type="checkbox"
                      name={vehicleIds}
                      value={vehicle.id}
                      checked={ids.includes(vehicle.id)}
                      onChange={() => handleChange(vehicle.id)}
                      className="form-check-input"
                    />
                  </div>
                </td>
                <td><img src={vehicle.image} width="60px" /></td>
                <td>{vehicle.stockNo}</td>
                <td>{vehicle.maker}</td>
                <td>{vehicle.model}</td>
                <td>{vehicle.chassisNo}</td>
                <td>{vehicle.mast}</td>
                <td>{vehicle.mastHeight}</td>
                <td>{currency(vehicle.bidPrice)}</td>
                <td>{vehicle.company}</td>
                {!isSc && <td>{vehicle.scNo ?? '-'}</td>}
                <td><a href={vehicle.linkPath}>{vehicle.linkName}</a></td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className={!isIvEdit ? 'd-flex mb-4 justify-content-end' : 'd-flex mb-4 justify-content-between'}>
        {
          isIvEdit &&
            <button
              onClick={handleClick}
              className="btn btn-secondary"
            >
              {!displayCheckbox ? 'Edit' : 'Cancel'}
            </button>
        }
        <div className="text-danger">合計: {countIds}台 / {currency(sumPrices)}</div>
      </div>
    </div>
  );
};
