import * as React from 'react'
import { useState } from 'react'
import CurrencyInput from 'react-currency-input-field'

interface Props {
  value?: string
  name: string
}

export const Price = (props: Props) => {
  const [value, setValue] = useState(props.value)

  return (
    <div>
      <CurrencyInput
        id="price-field"
        allowDecimals={false}
        defaultValue={value}
        onChange={(v) => setValue(v === null ? undefined : v)}
        className="form-control form-control-lg"
        style={{textAlign: 'center'}} />
      <input type="hidden" defaultValue={value} name={props.name} />
    </div>
  )
}
