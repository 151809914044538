// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
// import "./channels"
import "bootstrap/js/index.umd"
import { initializeComponents } from "./src/components"
import axios from 'axios'
import '@fortawesome/fontawesome-free/js/all';

Rails.start()
ActiveStorage.start()

document.addEventListener("DOMContentLoaded", () => {
  const meta = document.querySelector('meta[name="csrf-token"]')
  if (meta) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : meta.getAttribute('content')
    }
  }
  initializeComponents()
})
