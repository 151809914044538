import * as React from 'react'
import MultiSelect from 'react-multi-select-component'

type ValueType = string|number

interface OptionType {
  label: string
  value: ValueType
}

interface Props {
  name: string
  values: ValueType[]
  options: OptionType[]
}

export const Select = (props: Props) => {
  const defaultValue = props.options.filter((o) => (props.values.indexOf(o.value) !== -1))
  const [selected, setSelected] = React.useState(defaultValue)

  return (
    <div>
      <MultiSelect
        options={props.options}
        value={selected}
        onChange={setSelected}
        labelledBy={"Select"}
      />
      {
        selected.map(o => (
          <input key={o.value} type="hidden" name={props.name} value={o.value} />
        ))
      }
    </div>
  )
}
