import * as React from 'react'
import { useState } from 'react'
import axios from 'axios'

interface WatchResponse {
  path?: string
}

async function createWatch(path: string) {
  const res = await axios.post(path)
  const body = res.data as WatchResponse
  return body.path
}

async function destroyWatch(path: string) {
  await axios.delete(path)
}

interface Props {
  createPath: string
  destroyPath?: string
  className?: string
}

export const Fav = (props: Props) => {
  const [destroyPath, setDestroyPath] = useState(props.destroyPath)
  const color = destroyPath ? 'btn-secondary' : 'btn-warning'
  const text = destroyPath ? 'Remove from WatchList' : 'Add to WatchList'
  const onClick = async () => {
    if (destroyPath) {
      await destroyWatch(destroyPath)
      setDestroyPath(undefined)
    } else {
      const path = await createWatch(props.createPath)
      if (path) setDestroyPath(path)
    }
  }

  return (
    <a className={`btn btn-sm btn-block ${props.className || '' } ${color}`} onClick={onClick}>
      {text}
    </a>
  )
}
