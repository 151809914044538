import * as ReactDOM from 'react-dom'
import * as React from 'react'

import { Fav } from './fav'
import { Price } from './price'
import { Select } from './select'
import { VehicleCheckTable } from './VehicleCheckTable'

const Components = {
  Fav,
  Price,
  Select,
  VehicleCheckTable
}

type ComponentType = keyof (typeof Components)

function isComponentType(str?: string): str is ComponentType {
  return str !== undefined && Object.keys(Components).find(k => k === str) !== undefined
}

export function initializeComponents() {
  document.querySelectorAll("[data-react]").forEach(el => {
    if (el instanceof HTMLElement) {
      if (isComponentType(el.dataset.react)) {
        const Component = Components[el.dataset.react];
        if (Component !== undefined) {
          const dataStr = el.getAttribute('data')
          const props = (dataStr ? JSON.parse(dataStr) : {}) as any
          ReactDOM.render(
            React.createElement(Component as React.FunctionComponent<any> | React.ComponentClass<any, any>, props, null),
            el)
        }
      }
    }
  })
}
